 <template>
  <v-col
    :cols="cols"
    style="padding: 0px; padding-bottom: 10px; padding-right: 5px"
  >
    <v-hover v-slot:default="{ hover }" open-delay="200">
      <v-card :elevation="hover ? 16 : 2">
        <v-toolbar color="#1976d2" dark style="height: 40px">
          <v-btn icon style="margin-top: -20px">
            <v-icon>mdi-account-circle</v-icon>
          </v-btn>
          <v-toolbar-title style="margin-top: -20px"
            >Descargar CFDI Utaxme</v-toolbar-title
          >
        </v-toolbar>

        <v-container fluid>
          <v-row dense>
            <v-col cols="12">
              <!-- form inicial -->
              <v-form
                v-model="formDownload"
                ref="formDownload"
                style="margin-left: 40px; margin-top: -10px"
              >
                <v-container>
                  <!-- No de orden -->
                  <v-row style="margin-top: -15px">
                    <v-col cols="12" md="2">
                      <span
                        style="
                          margin-left: 0px;
                          font-weight: bold;
                          width: 100%;
                          position: absolute;
                          margin-top: 10px;
                        "
                        >No. Orden:</span
                      >
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        dense
                        solo
                        v-model="noOrden"
                        :rules="ordenRules"
                        maxlength="15"
                        label="No. Orden / Referencia"
                        required
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <a  href="#" @click="descargaXML">Descargar XML</a> -->
                      <v-btn
                        :loading="loadingBusqueda"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        @click="busqueda"
                        style="margin-top: 20px; margin-left: 80px"
                      >
                        <v-icon dark>mdi-magnify</v-icon>
                      </v-btn>
                    </template>
                    <span>Buscar</span>
                  </v-tooltip>
                      
                    </v-col>
                  </v-row>

                  <!-- RFC cliente -->
                  <v-row style="margin-top: -35px">
                    <v-col cols="12" md="2">
                      <span
                        style="
                          margin-left: 0px;
                          font-weight: bold;
                          width: 100%;
                          position: absolute;
                          margin-top: 10px;
                        "
                        >RFC Receptor:</span
                      >
                    </v-col>

                    <v-col cols="12" md="5">
                      <v-text-field
                        dense
                        solo
                        v-model="rfc"
                        :rules="rfcRules"
                        maxlength="13"
                        label="RFC Receptor"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-form>

              <v-divider style="margin-top: -15px"></v-divider>

              <v-card-actions>
                <!-- <v-row v-if="resultados" style="margin-top:-15px">
                                        <v-col cols="12" md="2">
                                           
                                        </v-col>
                                        <v-col cols="12" md="5">
                                            
                                        </v-col>
                                        <v-col cols="12" md="5">
                                           <v-btn @click="enviarCorreo"  class="mx-2" fab dark small color="success" style="margin-left:20px!important;margin-top:9px">
                                            <v-icon dark>mdi-send</v-icon>
                                          </v-btn>
                                        </v-col>
                                    </v-row> -->
              </v-card-actions>
              <!-- </v-card> -->
              <!-- ----------------------------- -->
            </v-col>
          </v-row>

          <v-row style="margin-top: -15px">
            <v-col cols="12" md="7">
              <v-row v-if="resultados">
                <v-col cols="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <!-- <a  href="#" @click="descargaXML">Descargar XML</a> -->
                      <v-btn
                        depressed
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        style="height: 30px"
                        @click="descargaXML"
                        ><v-icon>mdi-file-code</v-icon>Descargar</v-btn
                      >
                    </template>
                    <span>Descargar XML</span>
                  </v-tooltip>
                </v-col>
                <v-col cols="4">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        small
                        color="success"
                        v-bind="attrs"
                        v-on="on"
                        style="height: 30px; margin-left: 5px"
                        @click="descargaPDF"
                        ><v-icon>mdi-file-pdf-box</v-icon>Descargar</v-btn
                      >
                    </template>
                    <span>Descargar PDF</span>
                  </v-tooltip>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
        <!-- ------------------------------------------------------------ -->
      </v-card>
    </v-hover>

    <v-snackbar v-model="snackbar" :color="colorSnack">
      {{ mensajeSnack }}
      <v-btn text @click="snackbar = false">Cerrar</v-btn>
    </v-snackbar>
  </v-col>
</template> 

<script>
import operations from "../../operations";

export default {
  directives: {},
  components: {},
  name: "Descarga",
  data: () => ({
    archivoPdf: "",
    archivoXml: "",
    urlXML: "",
    urlPDF: "",
    loadingBusqueda: false,
    formCorreo: false,
    correo: "",
    mensajeSnack: "",
    colorSnack: "red",
    snackbar: false,
    rfc: "",
    resultados: false,
    noOrden: "",
    plataformaSeleccionada: {
      aplicaRetencion: false,
      ivaTrasladado: 0,
      plataforma: "Seleccionar",
      idPlataforma: "-1",
    },
    modelx: "",
    formDownload: false,
    title: "Descargar CFDI",
    src: "https://cdn.vuetifyjs.com/images/cards/house.jpg",
    rfcRules: [
      (v) => !!v || "El RFC es requerido",
      (v) =>
        (v && (v.length == 12 || v.length == 13)) ||
        "El RFC debe tener 12 o 13 caracteres",
    ],
    ordenRules: [
      (v) => !!v || "El número de orden es requerido",
      //v => /^[A-Za-z0-9_ ]+$/g.test(v) || 'Solo se aceptan letras y números',//'Solo letras y números',
      (v) => (v && v.length >= 3) || "Ingrese al menos 3 caracteres",
    ],
    emailRules: [
      (v) => !!v || "El E-mail es requerido",
      //v => /.+@.+/.test(v) || 'el E-mail debe ser válido',
      (v) =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "el E-mail debe ser válido",
    ],
    emailRulesDescargas: [
      //v => !!v || 'El E-mail es requerido',
      //v => /.+@.+/.test(v) || 'el E-mail debe ser válido',
      (v) =>
        /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
          v
        ) || "el E-mail debe ser válido",
    ],
    sinValidacion: [],
  }),
  props: {
    cols: String,
  },
  mounted() {
    //console.log("mounted");
  },
  methods: {
    enviarCorreo() {
      this.$refs.formDownload.validate();

      if (!this.formDownload) {
        this.snackbar = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Verifique los campos faltantes";
        this.resultados = false;
        return;
      }

      //    if(this.plataformaSeleccionada.idPlataforma=="-1"){
      //       this.snackbar=true;
      //       this.colorSnack='red';
      //       this.mensajeSnack="Seleccione la plataforma";
      //       this.resultados=false;
      //       return;
      //    }

      console.log("enviando correo...");
      if (this.correo.trim() == "") {
        this.snackbar = true;
        this.colorSnack = "red";
        this.mensajeSnack = "El correo es requerido";
        return;
      }

      this.snackbar = true;
      this.colorSnack = "green";
      this.mensajeSnack = "El correo se ha enviado...";
    },
    async busqueda() {
      console.log("buscando...");
      console.log(this.plataformaSeleccionada);
      this.resultados = false;
      this.$refs.formDownload.validate();

      if (!this.formDownload) {
        this.snackbar = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Verifique los campos faltantes";
        this.resultados = false;
        return;
      }

      if (this.plataformaSeleccionada.idPlataforma == "-1") {
        //     //   this.snackbar=true;
        //     //   this.colorSnack='red';
        //     //   this.mensajeSnack="Seleccione la plataforma";
        //     //   this.resultados=false;
        //     //   return;
        this.plataformaSeleccionada = "seleccionar";
      }

      try {
        this.loadingBusqueda = true;

        var response = {};

        response = await operations.CfdiGeneradosForm({
          emailReceptor: this.correo,
          rfcReceptor: this.rfc,
          plataforma: this.plataformaSeleccionada, //this.getPlataformaDesc(this.plataformaSeleccionada),
          referencia: this.noOrden,
        });

        console.log("response ws");
        console.log(response);

        if (response.status == "200") {
          this.resultados = true;
          if (response.data.lista == null) {
            console.log("No se encontraron resultados..");
            this.snackbar = true;
            this.colorSnack = "red";
            this.mensajeSnack = "No se encontraron resultados..";
            this.resultados = false;
          } else if (response.data.lista.length > 0) {
            console.log("Se encontraron resultados..");

            this.urlXML = this.b64DecodeUnicode(response.data.lista[0].urlXml);
            this.archivoXml = response.data.lista[0].archivoXml;
            this.urlPDF = response.data.lista[0].urlPdf;
            this.archivoPdf = response.data.lista[0].archivoPdf;

            this.snackbar = true;
            this.colorSnack = "green";
            this.mensajeSnack = "Se encontraron resultados..";
            this.resultados = true;
          } else {
            console.log("No se encontraron resultados..");
            this.snackbar = true;
            this.colorSnack = "red";
            this.mensajeSnack = "No se encontraron resultados..";
            this.resultados = false;
          }
        }

        this.loadingBusqueda = false;
      } catch (error) {
        console.log(error);
        this.loadingBusqueda = false;
        this.resultados = false;
        this.snackbar = true;
        this.colorSnack = "red";
        this.mensajeSnack = "Error al consultar: " + error;
      }
    },
    b64DecodeUnicode(str) {
      // Going backwards: from bytestream, to percent-encoding, to original string.
      return decodeURIComponent(
        atob(str)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );
    },
    getPlataformaDesc(idPlataforma) {
      var result = this.$store.state.catalogoPlataformasD.filter((obj) => {
        return obj.idPlataforma === idPlataforma;
      });

      console.log(result[0].plataforma);
      return result[0].plataforma;
    },
    descargaXML() {
      console.log("descargando xml...");
      //window.open(this.urlXML, "Download");
      //this.downloadFile(this.urlXML);

      //var xmltext = "<sometag><someothertag></someothertag></sometag>";
      var pom = document.createElement("a");

      //var filename = "file.xml";
      var pom = document.createElement("a");
      var bb = new Blob([this.urlXML], { type: "text/plain" });

      pom.setAttribute("href", window.URL.createObjectURL(bb));
      pom.setAttribute("download", this.archivoXml);

      pom.dataset.downloadurl = ["text/plain", pom.download, pom.href].join(
        ":"
      );
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();
    },
    dataURItoBlob(dataURI) {
      const byteString = window.atob(dataURI);
      const arrayBuffer = new ArrayBuffer(byteString.length);
      const int8Array = new Uint8Array(arrayBuffer);
      for (let i = 0; i < byteString.length; i++) {
        int8Array[i] = byteString.charCodeAt(i);
      }
      const blob = new Blob([int8Array], { type: "application/pdf" });
      return blob;
    },
    descargaPDF() {
      console.log("descargando pdf...");
      const blob = this.dataURItoBlob(this.urlPDF);

      var pom = document.createElement("a");

      pom.setAttribute("href", window.URL.createObjectURL(blob));
      pom.setAttribute("download", this.archivoPdf);

      pom.dataset.downloadurl = [
        "application/pdf",
        pom.download,
        pom.href,
      ].join(":");
      pom.draggable = true;
      pom.classList.add("dragout");

      pom.click();
    },
    downloadFile(filePath) {
      var link = document.createElement("a");
      link.href = filePath;
      link.download = filePath.substr(filePath.lastIndexOf("/") + 1);
      link.click();
    },
    // verConstancia(){
    //     if(this.$store.state.dashboard.constanciaUrl!=''){
    //     window.open(this.$store.state.dashboard.constanciaUrl, "_blank");
    //     }
    // }
  },
  getWindowHeight(event) {},
  beforeDestroy() {},
  created() {},
  computed: {
    vista() {
      return 6;
    },
  },
  beforeCreate: function () {},
};
</script>

<style scoped>
</style>
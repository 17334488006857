var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{staticStyle:{"padding":"0px","padding-bottom":"10px","padding-right":"5px"},attrs:{"cols":_vm.cols}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{attrs:{"elevation":hover ? 16 : 2}},[_c('v-toolbar',{staticStyle:{"height":"40px"},attrs:{"color":"#1976d2","dark":""}},[_c('v-btn',{staticStyle:{"margin-top":"-20px"},attrs:{"icon":""}},[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-toolbar-title',{staticStyle:{"margin-top":"-20px"}},[_vm._v("Descargar CFDI Utaxme")])],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-form',{ref:"formDownload",staticStyle:{"margin-left":"40px","margin-top":"-10px"},model:{value:(_vm.formDownload),callback:function ($$v) {_vm.formDownload=$$v},expression:"formDownload"}},[_c('v-container',[_c('v-row',{staticStyle:{"margin-top":"-15px"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',{staticStyle:{"margin-left":"0px","font-weight":"bold","width":"100%","position":"absolute","margin-top":"10px"}},[_vm._v("No. Orden:")])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"dense":"","solo":"","rules":_vm.ordenRules,"maxlength":"15","label":"No. Orden / Referencia","required":""},model:{value:(_vm.noOrden),callback:function ($$v) {_vm.noOrden=$$v},expression:"noOrden"}})],1),_c('v-col',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"margin-top":"20px","margin-left":"80px"},attrs:{"loading":_vm.loadingBusqueda,"color":"primary"},on:{"click":_vm.busqueda}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-magnify")])],1)]}}],null,true)},[_c('span',[_vm._v("Buscar")])])],1)],1),_c('v-row',{staticStyle:{"margin-top":"-35px"}},[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('span',{staticStyle:{"margin-left":"0px","font-weight":"bold","width":"100%","position":"absolute","margin-top":"10px"}},[_vm._v("RFC Receptor:")])]),_c('v-col',{attrs:{"cols":"12","md":"5"}},[_c('v-text-field',{attrs:{"dense":"","solo":"","rules":_vm.rfcRules,"maxlength":"13","label":"RFC Receptor","required":""},model:{value:(_vm.rfc),callback:function ($$v) {_vm.rfc=$$v},expression:"rfc"}})],1)],1)],1)],1),_c('v-divider',{staticStyle:{"margin-top":"-15px"}}),_c('v-card-actions')],1)],1),_c('v-row',{staticStyle:{"margin-top":"-15px"}},[_c('v-col',{attrs:{"cols":"12","md":"7"}},[(_vm.resultados)?_c('v-row',[_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"30px"},attrs:{"depressed":"","small":"","color":"success"},on:{"click":_vm.descargaXML}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-code")]),_vm._v("Descargar")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar XML")])])],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticStyle:{"height":"30px","margin-left":"5px"},attrs:{"depressed":"","small":"","color":"success"},on:{"click":_vm.descargaPDF}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file-pdf-box")]),_vm._v("Descargar")],1)]}}],null,true)},[_c('span',[_vm._v("Descargar PDF")])])],1)],1):_vm._e()],1)],1)],1)],1)]}}])}),_c('v-snackbar',{attrs:{"color":_vm.colorSnack},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.mensajeSnack)+" "),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.snackbar = false}}},[_vm._v("Cerrar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }